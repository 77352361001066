import React from "react"
import { connect } from "react-redux"
import HeaderDecider from "../components/headerDecider"

import pic1 from "../images/mob/about/about1.jpg"

const about = `https://player.vimeo.com/external/363864523.hd.mp4?s=5a1b509852490778c49af2cddf47bb8e76c976c2&profile_id=175`

const AboutPage = (props) => {

  let vidStyle = {
    flex: `none`
  }

  let vidCont = {

    display: `flex`,
    justifyContent: `center`,
    height: props.height,
    overflow: `hidden`,

  }

  if (props.width > 1240) {

    vidCont = {
      width: props.width
    }

    vidStyle = {
      width: props.width
    }

  }

  const aboutS = `Elefante es una productora audiovisual y estudio creativo establecido en México que produce contenido documental y de ficción para cine, TV y medios digitales.`
  
  const aboutE = `Elefante is a production company and creative studio established in Mexico that produces documentary and fiction content for film, TV and digital media.`

  let aboutDiv;

  let aboutMedia;

  if (props.width < 768) {

    const mobStyle = {
      ...props.coords, 
      top: 0, 
      left: 0, 
      right: 0, 
      paddingTop: `150px`, 
      opacity: 1, 
      paddingLeft: `30px`,
      paddingRight: `30px`,
      fontSize: `13px`,
      zIndex: 2
    }

    aboutDiv = <div style={mobStyle}>
      {props.lang == "ES" ? aboutS : aboutE}
    </div>

    const picStyle = {
      width: props.width,
      height: `100%`,
      position: `fixed`,
      overflow: `hidden`
    }

    const picCont = {
      position: `relative`,
    }

    aboutMedia = 
      <div style={picCont}>
        <img src={pic1} alt={pic1} style={picStyle} />
      </div>

  } else {

    aboutDiv = 
    <div style={ props.coords }>
      {props.lang == "ES" ? aboutS : aboutE}
    </div>

    aboutMedia = 
    <div style={vidCont}>
      <video rel="preconnect" style={vidStyle} muted={props.mute} src={about} loop autoPlay />
    </div>

  }

  return [  

    <HeaderDecider />,
    <div>{aboutDiv}</div>,
    <div>{aboutMedia}</div>
  ]

}

const mapStateToProps = (state) => {
  return {
      lang: state.lang,
      coords: state.coords,
      width: state.width,
      height: state.height,
      mute: state.mute
  }
}

export default connect(mapStateToProps)(AboutPage)

{/* <SEO title="About" />
   */}